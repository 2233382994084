<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="12">
                <h5> Formulario de Lecciones Aprendidas</h5>
              </b-col>
            </b-row>
          
            <b-row class="">
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  class="h-100 d-inline-block w-100"
                  name="ambit"
                  rules="requeridoE"
                >
                  <b-form-group label="Ambito" label-for="ambit" class="h-100">
                    <div class="d-flex w-100 justify-content-center align-items-center mt-2">
                      <b-form-radio v-model="dataItems.ambit" name="ambit" value="oficina" disabled>
                        Oficina Principal y Sedes
                      </b-form-radio>
                      <b-form-radio
                        v-model="dataItems.ambit"
                        name="ambit"
                        value="proyecto"
                        class="ml-2"
                        disabled
                      >
                        Proyecto
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-if="dataItems.ambit == 'proyecto'" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="project" rules="">
                  <b-form-group label="Proyecto" label-for="project" class="">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="proyectos"
                      label="code"
                      input-id="project"
                      :reduce="(proyectos) => proyectos.id"
                      placeholder="Proyecto"
                      v-model="dataItems.project_id"
                      disabled
                      @input="selectProyect(dataItems.project_id)"
                      ><template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-else class="">
                <validation-provider #default="{ errors }" name="area" rules="">
                  <b-form-group label="Area" label-for="area" class="">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="areas"
                      label="text"
                      input-id="area"
                      :reduce="(areas) => areas.value"
                      placeholder="Area"
                      v-model="dataItems.area_name"
                      disabled
                      >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="code" rules="">
                  <b-form-group label="Correlativo" label-for="code">
                    <b-form-input v-model="dataItems.code" id="code" placeholder="Codigo" disabled/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="correlative" rules="">
                  <b-form-group label="Codigo" label-for="code">
                    <b-form-input v-model="dataItems.correlative" id="correlative" placeholder="Codigo" disabled/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="register_by" rules="">
                  <b-form-group label="Registrado por" label-for="register_by">
                    <b-form-input
                      v-model="dataItems.register_by"
                      id="register_by"
                      placeholder="Resgistrado por"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="email" rules="correo">
                  <b-form-group label="Email" label-for="email">
                    <b-form-input
                      v-model="dataItems.email"
                      id="email"
                      type="email"
                      placeholder="Email"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>             
              <b-col md="6">
                <validation-provider #default="{ errors }" name="date_specification" rules="">
                  <b-form-group label="Fecha de Registro" label-for="date_specification">
                    <flat-pickr
                      id="date_specification"
                      v-model="dataItems.date_specification"
                      class="form-control"
                      :config="config"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- <b-col md="6">
                <validation-provider #default="{ errors }" name="date_last_update" rules="">
                  <b-form-group label="Ultima Actualización" label-for="date_last_update">
                    <flat-pickr
                      id="date_last_update"
                      v-model="dataItems.date_last_update"
                      class="form-control"
                      :config="config"
                      disabled
                      placeholder="Ultima Actualización"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col> -->
            </b-row>
            <b-row class="mb-1">
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="division" rules="">
                  <b-form-group label="Division" label-for="division">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="divisiones"
                      label="description"
                      input-id="division"
                      :reduce="(divisiones) => divisiones.abbreviation"
                      v-model="dataItems.division"
                      placeholder="Division"                    
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="category" rules="">
                  <b-form-group label="Título" label-for="category">
                    <v-select
                      v-if="dataItems.division != 'otros'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typesWorks"
                      label="description"
                      input-id="category"
                      v-model="dataItems.type_work"
                      :reduce="(typesWorks) => typesWorks.abbreviation"
                      placeholder="Título"
                      disabled
                      class="select-obra"
                    >
                  </v-select> 
                  <b-form-input
                      v-else
                      v-model="dataItems.type_work"
                      id="category"
                      placeholder="Título"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="speciality" rules="">
                  <b-form-group label="Especialidad" label-for="speciality">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="specialitys"
                      label="description"
                      input-id="speciality"
                      :reduce="(specialitys) => specialitys.abbreviation"
                      v-model="dataItems.speciality"
                      placeholder="Especialidad"
                      class="select-obra"
                      disabled
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="typification" rules="">
                  <b-form-group label="Tipificación" label-for="typification">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typifications"
                      label="description"
                      input-id="typification"
                      :reduce="(typifications) => typifications.abbreviation"
                      v-model="dataItems.typification"
                      placeholder="Tipificación"
                      class="select-obra"
                      disabled
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="">
                <validation-provider #default="{ errors }" name="status" rules="">
                  <b-form-group label="Estado" label-for="status" class="">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusLesson"
                      label="text"
                      input-id="status"
                      :reduce="(statusLesson) => statusLesson.value"
                      placeholder="Estados"
                      v-model="dataItems.status"
                      disabled
                      >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-if="dataItems.typification == 'otros'">
                <validation-provider #default="{ errors }" name="typification_others" rules="">
                  <b-form-group label="Otros (Especificar)" label-for="typification_others">
                    <b-form-input
                      v-model="dataItems.typification_others"
                      id="typification_others"
                      placeholder="Especificar"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  class="h-100 d-inline-block w-100"
                  name="is_security"
                  rules="requeridoE"
                >
                  <b-form-group label="Es de SSOMA?" label-for="is_security" class="h-100" style="font-weight: bolder; color: black; padding: 0.5rem; border-style: dashed;">
                    <div class="d-flex w-50 justify-content-center align-items-center mt-1">
                      <span class="mr-2"style="font-weight: bolder; color: black">No</span>
                      <b-form-checkbox v-model="dataItems.is_security" name="is_security" switch disabled>                       
                      </b-form-checkbox>                       
                       <span class="ml-2"style="font-weight: bolder; color: black">Si</span>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="12">
                <h5>Lección Aprendida</h5>
              </b-col>
              <b-col sm="12">
                <validation-provider #default="{ errors }" name="title_lesson" rules="">
                  <b-form-textarea
                      id="title_lesson"                    
                      rows="4"
                      max-rows="4"
                      v-model="dataItems.title_lesson"
                      no-resize
                      disabled
                    ></b-form-textarea>
                </validation-provider>             
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="12">
                <h5>Descripción de la Ocurrencia e Impacto</h5>
              </b-col>
              <b-col sm="12">
                <validation-provider #default="{ errors }" name="description_impact" rules="">
                  <b-form-group label="" label-for="description_impact" class="mb-0">
                    <b-form-textarea
                      id="description_impact"
                      placeholder="Descripción"
                      rows="4"
                      max-rows="4"
                      v-model="dataItems.description_impact"
                      no-resize
                      disabled
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Evaluación de las Causas</h5>
              </b-col>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="assessment_causes" rules="">
                  <b-form-textarea
                    id="assessment_causes"
                    placeholder="Causas Inmediatas"
                    rows="6"
                    max-rows="6"
                    v-model="dataItems.assessment_causes"
                    no-resize
                    disabled
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Mitigación del Impacto</h5>
              </b-col>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="impact_mitigation" rules="">
                  <b-form-textarea
                    id="impact_mitigation"
                    placeholder="Mitigación del Impacto (Acciones Adoptadas)"
                    rows="6"
                    max-rows="6"
                    no-resize
                    v-model="dataItems.impact_mitigation"
                    disabled
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Análisis y Conclusiones</h5>
              </b-col>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="conclusions" rules="">
                  <b-form-textarea
                    id="conclusions"
                    placeholder="Análisis y Conclusiones"
                    rows="6"
                    max-rows="6"
                    no-resize
                    v-model="dataItems.conclusions"
                    disabled
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <validation-observer #default="{ invalid }" ref="refFormObserverFiles">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="6">
                <h5>Archivos Adjuntos</h5>
              </b-col>             
            </b-row>       
          </b-form>
        </validation-observer>
        <b-row>
          <b-col md="12">
            <b-table
              class="position-relative"
              :hover="true"
              :items="files"
              responsive
              :fields="fields"
              show-empty
              empty-text="No existen"
              @sort-changed="sortChanged"
              no-border-collapse
            >
            <template #cell(actions)="data">
                <div class="d-flex align-items-center">
                  <b-media vertical-align="center" class="mr-1">
                    <a v-if="data.item.url != ''" target="_blank" :href="api_url + data.item.url">
                      <feather-icon icon="FileTextIcon" />
                    </a>
                  </b-media>                
                </div>
              </template>
              <template #cell(created_at)="data">
                <span>{{ moment(data.item.created_at).format('yyyy-MM-DD') }}</span>
              </template>            
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col sm="3">
                  <b-form-group
                    label-cols="4"
                    label-cols-md="4"
                    label-size="md"
                    label="Entradas"
                    label-for="input-md"
                  >
                    <b-form-select
                      v-model="showEntrie"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="entries"
                      @change="changeSizePage"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="3" class="mt-75" style="font-size: 12px">
                  <span> {{ totalElements }} Registros en total</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalElements"
                    :per-page="showEntrie"
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    @change="cambioPagina"
                    pills
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="15" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="15" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="p-1 d-flex justify-content-center">          
            <b-button class="mr-2 mb-1 mb-md-0" variant="danger" @click="cancel">
              <span class="text-nowrap"> Volver </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import LessonService from '@/services/LessonService'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import ProjectsService from '@/services/ProjectsService'
import AreasService from '@/services/AreasService'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import TypeWorkService from "@/services/TypeWorkService";
import SpecialityLessonService from "@/services/SpecialityLessonService";
import TypificationService from "@/services/TypificationService";
import DivisionService from "@/services/DivisionService";
const APIURL = process.env.APIURLFILE

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      required,
      api_url: APIURL,
      showLoading: false,
      tabIndex: 0,
      records: [],
      files: [],
      filesTemp: [],
      fields: [
        { key: 'rownumber', label: 'Nro', sortable: false,visible: true },
        { key: 'description', label: 'Descripción', sortable: false },
        { key: 'created_at', label: 'Fecha Actualización', sortable: false },
        { key: 'file_name', label: 'Nombre Archivo', sortable: false },
        { key: 'actions', label: 'Acciones',visible: true }
      ],
      prioridades: [
        {
          id: 1,
          name: 'Alta'
        },
        {
          id: 2,
          name: 'Media'
        },
        {
          id: 3,
          name: 'Baja'
        }
      ],
      config: {
        plugins: [
          ShortcutButtonsPlugin({
            theme: 'dark',
            button: [{ label: 'Hoy' }],
            onClick(index, fp) {
              let date = index ? new Date(Date.now() + 24 * index * 60 * 60 * 1000) : new Date()
              fp.setDate(date)
              fp.close()
            }
          })
        ],
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
          },
          months: {
            shorthand: [
              'Ene',
              'Feb',
              'Mar',
              'Abr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Sep',
              'Оct',
              'Nov',
              'Dic'
            ],
            longhand: [
              'Enero',
              'Febreo',
              'Мarzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }
        }
      },
      areas: [
        {
          text: 'Comunicaciones',
          value: 'COM'
        },
        {
          text: 'Tecnologías de las Información',
          value: 'TI'
        },
        {
          text: 'Selección y Desarrollo',
          value: 'SEL&DESA'
        },
        {
          text: 'Nómina y Relaciones Laborales',
          value: 'NOM&RL'
        },
        {
          text: 'Responsabilidad Social y Bienestar',
          value: 'RRSS'
        },
        {
          text: 'Archivo',
          value: 'ARCHIV'
        },
        {
          text: 'Impuestos y Cuentas por Pagar',
          value: 'CXP'
        },
        {
          text: 'Finanzas y Seguros',
          value: 'FINANZAS'
        },
        {
          text: 'Costos y Control de Gestión',
          value: 'CO&CG'
        },
        {
          text: 'Contabilidad',
          value: 'CONTA'
        },
        {
          text: 'Logística',
          value: 'LOG'
        },
        {
          text: 'Almacén Central y Transporte',
          value: 'ALM'
        },
        {
          text: 'Negocios',
          value: 'NEG'
        },
        {
          text: 'Propuestas',
          value: 'PROP'
        },
        {
          text: 'SSOMA',
          value: 'SSOMAC'
        },
        {
          text: 'Equipos',
          value: 'EQUIP'
        },
        {
          text: 'Contratos',
          value: 'CONTRATOS'
        },
        {
          text: 'Legal',
          value: 'LEGAL'
        },
        {
          text: 'Planeamiento',
          value: 'PLANEAM'
        }
      ],
      alltypesWorks: [],
      typesWorks: [],
      divisiones: [],
      specialitys: [],
      typifications: [],    
      proyectos: [], 
      showLoading: false,
      dataItems: {
        project_id: null,
        is_security:false,
        correlative:'',
        area_name: null,
        code: '',
        ambit: '',
        register_by: '',
        email: '',
        date_specification: new Date(),
        date_last_update: null,
        speciality: '',
        division: '',
        type_work: '',
        typification: '',
        typification_others: '',
        title_lesson: '',
        description_impact: '',
        assessment_causes: '',
        impact_mitigation: '',
        conclusions: '',
        status:''
      },
      statusLesson:[],
      userData: JSON.parse(localStorage.getItem('userData')),
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_lesson,
      items: {
        description: '',
        file: null,
        lesson_id: null
      },
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      alltypifications:[],
      alltypeWorks:[],
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr
  },
  async mounted() {
    await this.getSelect()
    await this.getBaseData()
    await this.getData()
    this.items.lesson_id = this.$route.params.id
   
  },
  methods: {   
    async getBaseData() {
      const url = `?limit=100&`;
      this.showLoading = true;
      const respProyectos = await ProjectsService.getProyectosAll(
        url,
        this.$store
      );
      console.log("respProyectos", respProyectos);
      if (respProyectos) {
        this.proyectos = respProyectos.data.rows;
      }
      const respDivisions = await DivisionService.getDivisions(
        url,
        this.$store
      );
      console.log("respDivisions", respDivisions);
      if (respDivisions) {
        this.divisiones = respDivisions.data.rows;
      }
      const respTypeWork = await TypeWorkService.getTypeWorks(
        url,
        this.$store
      );
      console.log("respTypeWork", respTypeWork);
      if (respTypeWork) {
        this.alltypeWorks = respTypeWork.data.rows;
      }
      const respSpeciality = await SpecialityLessonService.getSpecialityLessons(
        url,
        this.$store
      );
      console.log("respSpeciality", respSpeciality);
      if (respSpeciality) {
        this.specialitys = respSpeciality.data.rows;
        const order = [
          "Movimiento de Tierras",
          "Civil",
          "Estructuras",
          "Mecánica",
          "Electrica e Instrumentacion",
          "Sanitaria",
          "Otras"
        ];

        this.specialitys = this.specialitys.sort((a, b) => order.indexOf(a.description) - order.indexOf(b.description));
      }
      const respTypifications = await TypificationService.getTypifications(
        url,
        this.$store
      );
     
      if (respTypifications) {
        this.alltypifications = respTypifications.data.rows;         
      }    

      this.showLoading = false;
    },
    cambioPagina(e) {
      this.currentPage = e
      this.totalElements = this.files.length
    },
    changeSizePage() {
      this.totalElements = this.files.length
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    cancel() {
      //this.$router.push('/lecciones')
      this.$router.go(-1);
    },
    async getSelect() {
      const url = `?limit=100&`
      this.showLoading = true
      const respProyectos = await ProjectsService.getProyectos(url, this.$store)
     
      if (respProyectos) {
        this.proyectos = respProyectos.data.rows
      }

      this.showLoading = false
    },
    async getFiles() {
      this.showLoading = true

      let url =
        `?filter=` +
        '[{"keyContains":"lesson_id","key":"equals","value":' +
        this.$route.params.id +
        '}]'
      const resp = await LessonService.getFiles(url, this.$store)

      if (resp.status) {
        this.files = resp.data.rows
        this.totalElements = resp.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
  
    async getData() {
      this.showLoading = true
      const getLesson = await LessonService.getLessonById(this.$route.params.id, this.$store)
      console.log({getLesson})
      if (getLesson.status) {
       
        this.dataItems = {
          correlative:  getLesson.data.correlative,
          project_id: getLesson.data.project_id,
          area_name: getLesson.data.area_name,
          code: getLesson.data.code,
          is_security:getLesson.data.is_security,
          register_by: getLesson.data.register_by,
          ambit: getLesson.data.ambit,
          email: getLesson.data.email,
        
          date_specification:   moment(getLesson.data.date_specification, 'yyyy-MM-DD HH:mm').utc(getLesson.data.date_specification).format('yyyy-MM-DD'),
          date_last_update: getLesson.data.date_last_update,
          division: getLesson.data.division,
          type_work: getLesson.data.type_work,
          speciality: getLesson.data.speciality,
          typification: getLesson.data.typification,
          typification_others: getLesson.data.typification_others,
          title_lesson: getLesson.data.title_lesson,
          description_impact: getLesson.data.description_impact,
          assessment_causes: getLesson.data.assessment_causes,
          impact_mitigation: getLesson.data.impact_mitigation,
          conclusions: getLesson.data.conclusions,
          status:getLesson.data.status
        }
       

        if(this.user_role =='user-lesson'){
          this.statusLesson=[
            {
              text: 'Provisional',
              value: 'Provisional',          
            },
            {
              text: 'Aprobado',
              value: 'Aprobado',          
            },
            {
              text: 'Rechazado',
              value: 'Rechazado',          
            },
          ]
        }else {
          this.statusLesson=[
            {
              text: 'Aprobado',
              value: 'Aprobado',          
            },
            {
              text: 'Oficial',
              value: 'Oficial',          
            },
            {
              text: 'Rechazado',
              value: 'Rechazado',          
            },
          ]
        }

        this.selectProyect(getLesson.data.project_i)
        this.changeDiv(getLesson.data.division, getLesson.data.type_work)
        this.getFiles(this.$route.params.id)
        this.getDefaultTypification(this.dataItems.is_security)    
      }
      this.showLoading = false
    },
    async getDefaultTypification(is_ssomac){
      console.log('default 827',is_ssomac)
      const typification = this.alltypifications.filter((e) => e.is_ssomac == is_ssomac);
      console.log(831,typification)
      if (typification.length > 0) {
        this.typifications = typification;
        this.typifications.push({
          description: "Otros",
          abbreviation: "otros",
        });
      }
    },
    async selectProyect(id) {
      this.showLoading = true
      let url =
        `?limit=10000&filter=` +
        JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const respAreas = await AreasService.getAreas(url, this.$store)
    
      if (respAreas) {
        this.areas = respAreas.data.rows
      }
      this.showLoading = false
    },
    changeDiv(name, type_work =  null) {
      this.typesWorks = [];
      const typeWork = this.alltypeWorks.filter((e) => e.division_lesson.abbreviation == name);
    
      if (typeWork.length > 0) {
       
        this.typesWorks = typeWork;
      }
      if(type_work){
        this.dataItems.type_work = type_work
      }
    },
    async saveData() {
      this.showLoading = true
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
         
          this.dataItems.date_specification = new Date(this.dataItems.date_specification)
          const save = await LessonService.updateLesson(this.$route.params.id, this.dataItems, this.$store)
          if (save.status) {
            this.$swal({
                title: 'Actualizado',
                text: 'Los datos han sido actualizados con éxito.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              this.$router.push('/lecciones')
          } else {
            this.$swal({
              title: 'Error!',
              text: 'Ocurrió un error al actualizar los datos de los formularios.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        }
      })
      this.showLoading = false
    },
    resetForm() {
      this.$refs.refFormObserverFiles.reset()
      this.items.description = ''
      this.items.file = null
    },
    async saveFile() {
      this.showLoading = true
      let datos = new FormData()
      this.$refs.refFormObserverFiles.validate().then(async (success) => {
       
        if (success) {
          datos.append('description', this.items.description)
          datos.append('file', this.items.file)
          datos.append('lesson_id', Number(this.items.lesson_id))
          const resp = await LessonService.saveFile(datos, this.$store)
          if (resp.status) {
            this.$swal({
              title: 'Registrado',
              text: 'El archivo ha sido registrado con éxito.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            this.resetForm()
            this.getFiles()
          } else {
            this.$swal({
              title: 'Error!',
              text: ' Hubo un error al registrar el archivo',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        }
      })
      this.showLoading = false
      // console.log('items', this.items)
    },
    async deleteAction(data) {
      this.$swal({
        title: '¿Desea eliminar este archivo? Se eliminará permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteData(data.id)
        }
      })
    },
    async deleteData(id) {
    
      const resp = await LessonService.deleteFile(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
        this.$swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'El archivo ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
        this.getFiles()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar el archivo seleccionado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.select-obra .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}
.shortcut-buttons-flatpickr-buttons {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.shortcut-buttons-flatpickr-button {
  background-color: rgb(115, 103, 240);
  border: 0px;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
}
.shortcut-buttons-flatpickr-button:hover {
  background-color: rgb(129, 119, 230);
}
</style>
